<template>
    <div class="page" ref="page">
        <div class="info" v-show="false">
              
            <div class="tips mybox">
                 <div class="mybox1" style=" 
                border-radius: 0px 0px 50% 50% / 10px 0px 100% 100%;width:100%;
             height: 50px;overflow: hidden;background-color: #F25745;
                border-top: 0px;">
                     
                 </div>
                 
            </div>
           
        </div>
         
        <div class="redinfo" v-show="false">
        <div class="row">
           <div class="left" v-show="false">
               <img class="avatar" :src="redEnvelope.employees.avatar">
           </div>
           <div class="right">
               <div class="user-info" >
                   <div class="name" style="font-size: 18px; font-weight: 600;">金阁文化的红包</div> 
               </div>
              
           </div> 
        </div> 
        <div class="tips" style="font-size: 14px; color: #BDBDBD;margin: 10px;">
          恭喜发财，大吉大利
        </div>
        </div>

        <div class="list" style="background-color: #E6E6E6;background-size: cover; margin-top:400px;"> 
            <div class="content">
                <div>  
                          <div class="row">
                              <div class="left">
                                  <img :src="redEnvelope.avatar" class="avatar">
                              </div>
                              <div class="right">
                                  <div class="user-info">
                                      <div class="name">{{redEnvelope.nickname}}</div>
                                      <div class="time">{{redEnvelope.updatedAt}}</div>
                                  </div>
                                  <div class="tips">
                                      <div class="t1">{{redEnvelope.amount}}元</div>
                                       <div class="t2" v-if="redEnvelope.status==-1">领取失败</div>
                                       <div class="t2" v-else>手气最佳</div>
                                  </div>
                              </div>
                          </div>
                          
                          <div class="row">
                              <img src="../../static/images/jinge1.png" alt="" style="width:100%">
                          </div>
                          <div class="row">
                              <img src="../../static/images/jinge2.png" alt="" style="width:100%">
                          </div>
                          <div class="row">
                              <a @click="showonSharevisible"><img src="../../static/images/jinge3.png" alt="" style="width:100%"></a>
                          </div> 
                  </div>
                
            </div>
        </div>
        
        
        
        <a-drawer
              
              title=""
              placement="bottom"
              :closable="false" 
              :height="drawerH"
              :visible="sharevisible"
              wrapClassName="drawer-style"
              @close="onSharevisibleClose"
              :bodyStyle="{padding:'0px'}"
            >
            
              <div class="code-image">
                  <div class="code-border">
                      <!--  个人海报  -->
                      <div >
                          
                          
                          <div id="code-template" ref="template" v-show="!posterImage">
                              
                          </div>
                          <div style="position: absolute;left: 50%;top: 15%;transform: translate(-50%,-15%);margin-bottom:50px;overflow: auto;">
                           <img  :src="posterImage" alt="" v-if="posterImage" :height="imgheight" :width="imgwidth">
                         
                          </div>
                      </div> 
                  </div>
              </div>
            </a-drawer>
          <canvas ref="canvas" :width="w" :height="h">
              您的浏览器不支持canvas
          </canvas>
        
          
          
    </div>
    
    
</template>

<script>
import {redEnvelopeIndexApi, openUserInfoApi,posterApi} from "@/api/redEnvelope"; 
export default { 
    name: "speed",
    data() {
        return {
            redEnvelope: {}, 
            isEnd: false,    
            timer: 0,
            
            w:720,
            h:1280,
            infoheight:300,
            drawerH:600,
            imgwidth:500,
            imgheight:1000,
            dialog:{visible:false}, 
            wxUserData:{},
            //海报图片
            posterImage: '',        
            posterdatalist:{},
            sharevisible:false,
            isEnd: false,
            giftMaskFlag: false,
            giftInfo: {},
            codeMaskFlag: false,
            codeUrl: '',
            failMaskFlag: false,
            dataList: {},
            //助力好友记录
            friendList: [],
            //红包发放记录
            redlist:[],
            //活动信息
            content:'',
            countDown: {
                day: '-',
                hour: '-',
                min: '-',
                sec: '-',
            },
            timer: 0,
            fissionContactId:0,
            apiInfo: false,
        }
    },
    created() {
        // console.log('ssss')
         this.fissionId = this.$route.query.id;
        // 需要兼容老的query参数
        if (this.$route.query.parentUnionId !== undefined) {
          this.parentUnionId = this.$route.query.parentUnionId;
        } else if (this.$route.query.parent_union_id !== undefined) {
          this.parentUnionId = this.$route.query.parent_union_id;
        }
        
        if (this.$route.query.wxUserId !== undefined) {
          this.wxUserId = this.$route.query.wxUserId;
        } else if (this.$route.query.wx_user_id !== undefined) {
          this.wxUserId = this.$route.query.wx_user_id;
        }
        
        
        this.getOpenUserInfo() 
    },
    methods: { 
        getOpenUserInfo() {
            let that = this;
            openUserInfoApi({
              id: that.fissionId
            }).then((res) => {
              if (res.data.openid === undefined) {
               let redirectUrl = '/auth/redEnvelope?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
               that.$redirectAuth(redirectUrl);
              }
              this.wxUserData = res.data;
              this.getPosterData();
        })
        },
        //  获取海报数据
        getPosterData() {
          let params = {
            id: this.fissionId,
            unionId: this.wxUserData.unionid
          }
          
          redEnvelopeIndexApi(params).then((res) => {
              this.redEnvelope=res.data;
              this.speedUrl = '/redEnvelope/speed?id=' + res.data.fissionId + '&fissionContactId=' + res.data.fissionContactId + '&type=';
              
              this.getPosterDataPoster();
          })
        },
        
        getPosterDataPoster() {
          
          let params = {
                fissionId: this.redEnvelope.fissionFissionId,
                unionId: this.wxUserData.unionid,
                nickname: this.wxUserData.nickname,
                avatar: this.wxUserData.headimgurl,
                openId:this.wxUserData.openid
            }
            posterApi(params).then((res) => {
                this.posterdatalist = res.data
                
                this.createPoster()
            })
          
        },
        
        async createPoster() {
          try{
            const ctx = this.$refs.canvas.getContext('2d')
            this.drawerH=this.$refs.page.clientHeight-50;
            // this.imgheight=this.drawerH-50
            // this.imgwidth=this.imgheight/1280*720;
            
            this.imgwidth=this.$refs.page.clientWidth-60
            this.imgheight=this.imgwidth/720*1280
            const bili=this.imgwidth/720
            // console.log(this.drawerH)
            this.w =720
            //this.$refs.page.clientWidth
            //document.documentElement.clientWidth-20
           //this.h=document.documentElement.clientHeight-50
            //720
            this.h =1280
            //this.w/720*1280
            //1280 
            const bg = await this.getImg(this.posterdatalist.coverPic)
            const qr = await this.getImg(this.posterdatalist.qrcodeUrl)
        //720-550=170   1280-1100=170
        // "qrcodeW": "50",  "qrcodeH": "50",  "qrcodeX": "174",  "qrcodeY": "362",
            ctx.drawImage(bg, 0, 0, this.w, this.h)
            ctx.drawImage(qr, 
            this.posterdatalist.qrcodeX*3, 
            this.posterdatalist.qrcodeY*3, 
            this.posterdatalist.qrcodeW*3, 
            this.posterdatalist.qrcodeH*3)
        //     console.log(this.posterdatalist)
        // console.log(this.wxUserData)
            if (this.wxUserData.nickname) {
                ctx.font = '30px Verdana'
                ctx.fillStyle = this.posterdatalist.nicknameColor ?? '#fff'
                ctx.fillText(this.wxUserData.nickname, 115, 74)
            }
        
            if (this.wxUserData.headimgurl) {
                const avatar = await this.getImg(this.wxUserData.headimgurl)
                ctx.drawImage(avatar, 30, 30, 50, 50)
            }
        
            this.posterImage = this.$refs.canvas.toDataURL('image/png')
            }catch(e){
                console.log(e)
                //alert(JSON.stringify(e));
            }
        },
        getImg(url) {
            url += '?rand=' + Math.random()
            return new Promise(resolve => {
                const img = new Image()
                img.crossOrigin = "anonymous";
                img.onload = () => {
                    resolve(img)
                }
                img.onerror = err => {
                    console.log('图片加载失败');
                }
                img.src = url
            })
        },
        
        showonSharevisible() {
        // this.dialog.visible=true;
          this.sharevisible = true;
          
        },
        
        showonApiInfo() {
        // this.dialog.visible=true;
          if(this.apiInfo == true){
              this.apiInfo = false;
          }else if(this.apiInfo == false){
              this.apiInfo = true;
          }

        },
        onSharevisibleClose() {
          this.sharevisible = false;
        },
    }
}
</script>

<style lang="less" scoped>

canvas {
        position: absolute;
        top: 0;
        left: 0; 
        width: 720px;
        height: 1280px;
        z-index: 10000;
        display: none;
    }
    
.page {
    width: 100vw;
    height: 100vh;
    /*background-color: #fff;*/
    padding: 16px;
    position: relative; 
    /*background-size: cover;*/
    display: flex;
    flex-direction: column;
    
    background-image: url('../../static/images/background1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .info { 
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;

        .tips {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 10px;

            .tip {
                color: saddlebrown;
            }

            .num {
                padding: 0 6px;
                color: #ff5636;
                font-weight: bold;
            }
        }

        .total {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .icon {
                width: 4px;
                height: 14px;
                border-radius: 3px;
                background-color: darkorange;
                margin-right: 6px;
            }

            .num {
                padding: 0 6px;
                color: #ff5636;
            }
        }

        .gift {
            display: flex;
            overflow: auto;
            margin-bottom: 8px;

            .item {
                min-width: 86px;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;

                .top {
                    width: 70px;
                    height: 70px;
                    background-color: orange;
                    border-radius: 10px;
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    overflow: hidden;

                    .icon {
                        width: 50%;
                        height: 50%;
                    }

                    .received {
                        width: 100%;
                        text-align: center;
                        position: absolute;
                        background-color: #ffc271;
                        color: saddlebrown;
                        font-size: 12px;
                        bottom: 0;
                    }
                }

                .top-received {
                    background-color: #ffd6a1;

                    .icon {
                        opacity: .5;
                    }
                }

                .bottom {
                    width: 100%;
                    position: relative;

                    .lines {
                        display: flex;
                        align-items: center;

                        .line {
                            width: 50%;
                            height: 4px;
                            background-color: #ffe1c4;
                        }

                        .light {
                            background-color: orange;
                        }
                    }

                    .round {
                        position: absolute;
                        top: 0 - 12px / 2 + 4px / 2;
                        left: calc(50% - (12px / 2));
                        width: 12px;
                        height: 12px;
                        background-color: #ffd6a1;
                        border-radius: 12px;
                    }

                    .light {
                        background-color: orange;
                    }
                }

                .level {
                    font-size: 12px;
                    margin-top: 2px;
                    text-align: center;
                    color: #ffaf45;
                }
            }
        }

        .countdown {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: grey;

            .time {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                margin: 0 5px;
                background-color: #ffe4bd;
                color: darkorange;
                font-size: 12px;
                font-weight: bold;
            }
        }
    }

    .list { 
        border-radius: 10px;
        padding: 5px 5px 1px;
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .title {
            display: none;
            width: 100%;
            top: -4px;
            left: 0;
            position: absolute;
            display: flex;
            justify-content: center;

            .text {display: none;
                padding: 3px 8px;
                color: white;
                border-radius: 0 0 6px 6px;
                background-color: #ffaf45;
            }
        }

        .content {
            height: 0;
            flex-grow: 1;
            overflow-y: auto;

            .row {
                padding: 14px 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                display: flex;
                align-items: center;

                .left {
                    margin-right: 14px;

                    .avatar {
                        width: 44px;
                        height: 44px;
                        border-radius: 50%; 
                    }
                }

                .right {
                    flex-grow: 1;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .user-info {
                        .name {
                            color: saddlebrown;
                            font-size: 20px; 
                            color: black;
                        }

                        .time {
                            color: #A6A6A6;
                            font-size: 16px;
                        }
                    }

                    .tips {
                        .t1 {
                            font-size: 16px;
                            color: #A6A6A6;
                        }
                        .t2{
                            font-size: 16px;
                            color: #E1C25C;
                        }
                    }
                }
            }
        }
    }


.redinfo { 
        border-radius: 10px;
        padding: 5px 5px 1px;  
        text-align: center; 
            .row { 
                display: flex;
                flex-direction: row;
                justify-content: center; 
                .left {    
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .avatar {
                        width: 44px;
                        height: 44px;
                        border-radius: 50%; 
                    }
                }

                .right { 
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 10px;
                    .user-info {
                        .name {
                            color: saddlebrown;
                            font-size: 20px; 
                            color: black;
                        }

                        .time {
                            color: #A6A6A6;
                            font-size: 16px;
                        }
                    }

                    .tips {
                        .t1 {
                            font-size: 16px;
                            color: #A6A6A6;
                        }
                        .t2{
                            font-size: 16px;
                            color: #E1C25C;
                        }
                    }
                }
            } 
    }



    .mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.64);
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            width: 84%;
            padding: 20px 0 30px;
            background-color: white;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                font-size: 16px;
                color: black;
            }

            .gift-image {
                width: 70%;
            }

            .tip {
                margin-top: -15%;
                margin-bottom: 10px;
            }

            .get-gift {
                background-color: #ff5636;
                width: 50%;
                height: 32px;
                display: flex;
                color: white;
                border-radius: 32px;
                justify-content: center;
                align-items: center;

            }
        }
    }

    .code-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.64);
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            width: 84%;
            padding: 20px 0 30px;
            background-color: white;
            display: flex;
            border-radius: 6px;
            flex-direction: column;
            align-items: center;

            .title {
                color: black;
                margin-bottom: 10px;
                font-size: 16px;
            }

            .tip {
                color: saddlebrown;
                font-size: 13px;
            }

            .code-image {
                width: 70%;
                margin-bottom: 20px;
            }

            .bottom-tip {
                font-size: 15px;
            }
        }
    }

    .fail-mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.64);
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
            width: 84%;
            padding: 20px 0 30px;
            background-color: white;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                font-size: 17px;
                color: black;
                margin-bottom: 20px;
            }

            .fail-image {
                width: 34%;
                margin-bottom: 12px;
            }

            .tip {
                margin-bottom: 20px;
            }

            .fail-btn {
                background-color: #ff5636;
                width: 70%;
                height: 40px;
                font-size: 15px;
                display: flex;
                color: white;
                border-radius: 32px;
                justify-content: center;
                align-items: center;
            }
        }
    }

}
.ant-tabs-nav .ant-tabs-tab{margin: 0px;}
.mybtn{margin:5px auto; margin-top:50px; width:200px;  height:40px;color: red;
                               text-align: center; line-height: 40px;
                     background:linear-gradient(to bottom, #fcffa2,#fbb700);font-weight: 600;font-size: 20px;
                     -webkit-border-radius: 1em;-moz-border-radius: 1em;}
:global(.drawer-style .ant-drawer-body){padding: 0px;}
</style>